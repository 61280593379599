<nav class="navbar navbar-expand-sm navbar-dark bg-primary justify-content-between">
	<a class="navbar-brand" routerLink="">
		<i class="fa fa-clock-o"></i>
		MC Rookie 2022
	</a>

	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav ml-auto">
			<!--<li class="nav-item dropdown" routerLinkActive="active">-->
				<!--<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
					<!--Results-->
				<!--</a>-->
				<!--<div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
					<!--<a class="dropdown-item" [routerLink]="['/ranking/race-results', 3]" routerLinkActive="active">Race 3</a>-->
					<!--<a class="dropdown-item" [routerLink]="['/ranking/race-results', 4]" routerLinkActive="active">Race 4</a>-->
					<!--<div class="dropdown-divider"></div>-->
					<!--<a class="dropdown-item" routerLink="/ranking/season-results" routerLinkActive="active">Season standings</a>-->
				<!--</div>-->
			<!--</li>-->
			<!--<li class="nav-item dropdown" routerLinkActive="active">-->
				<!--<a class="nav-link dropdown-toggle" href="#" id="resultsImportDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
					<!--Gepard-->
				<!--</a>-->
				<!--<div class="dropdown-menu" aria-labelledby="resultsImportDropdown">-->
					<!--<a class="dropdown-item" routerLink="/gepard/results-import" routerLinkActive="active">Import wyników</a>-->
					<!--<a class="dropdown-item" routerLink="/gepard/live-timing" routerLinkActive="active">Live timing</a>-->
				<!--</div>-->
			<!--</li>-->
			<li class="nav-item" routerLinkActive="active" *ngFor="let race of races$ | async">
				<a class="nav-link" [routerLink]="['/ranking/race-results', race.id]" routerLinkActive="active">{{race.name}}</a>
			</li>
			<li class="nav-item" routerLinkActive="active">
				<a class="nav-link" routerLink="/ranking/season-results" routerLinkActive="active">Season</a>
			</li>
		</ul>
	</div>
</nav>

<div class="container-fluid layout-content-wrapper">
	<div class="layout-content">
		<router-outlet></router-outlet>
	</div>
</div>
